import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/psdev/Documents/gitdev/workspace/working/myproject/hds25-gatby4-anndream-website-2022/site/src/components/layout.js";
import { Link } from "hds-react";
import ExternalLink from '../../../components/ExternalLink';
import LeadParagraph from '../../../components/LeadParagraph';
import Image from '../../../components/LeadParagraph';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "photography",
      "style": {
        "position": "relative"
      }
    }}>{`Photography`}<a parentName="h1" {...{
        "href": "#photography",
        "aria-label": "photography permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h1>
    <LeadParagraph mdxType="LeadParagraph">
  Photographs can be used for communicating concepts and emotions hard to put into words, and bringing a strong feeling of Helsinki into your service.
    </LeadParagraph>
    <p>{`The City of Helsinki’s pictorial narrative is built around four themes:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Changing Helsinki:`}</strong>{` Change can be seen not only in the vast area development projects, but also in, for example, the economic structure and in the increasingly international streetscape.`}</li>
      <li parentName="ul"><strong parentName="li">{`Functional Helsinki:`}</strong>{` Helsinki is a smart, safe and compact city, where we are jointly designing a modern city life.`}</li>
      <li parentName="ul"><strong parentName="li">{`Helsinki of contrasts:`}</strong>{` Helsinki has its own distinctive style and atmosphere, which we as citizens can be proud of. We do not want to be slick glossy cosmopolites, but there is an edge to us.`}</li>
      <li parentName="ul"><strong parentName="li">{`Original Helsinki:`}</strong>{` Helsinki is a city where dissimilarity is appreciated. Here, everyone can be themselves, no matter how eccentric or regular you are.`}</li>
    </ul>
    <p>{`In user interfaces, photographs can be used for example in hero elements, text/photo modules or card components.`}</p>
    <p>{`See the `}<ExternalLink href="https://brand.hel.fi/en/photograph-guidelines/" mdxType="ExternalLink">{`Visual Identity Guidelines - Photograph guidelines`}</ExternalLink>{` for more information on using photographs.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      